import { BoxProps, Flex, Image, Text } from '@chakra-ui/react';

export function SetupBranding(props: BoxProps) {
  return (
    <Flex {...props} gap="1" color="neutral.white">
      <Text textStyle="h2">Strive</Text>
      <Image
        src="/images/striveware/silver-ring-red-light.webp"
        w="36px"
        alt="Biometric Ring"
      />
      <Text textStyle="h2">Ware</Text>
    </Flex>
  );
}
