import { useMemo } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';

import { LottieFile, useGradient } from '@arena-labs/strive2-ui';

export function SetupScanning() {
  const background = useGradient('StriveWare.bgDarkGradient');
  const animation = useMemo(
    () => import('./scanning-for-devices.lottie.json'),
    [],
  );
  return (
    <Flex
      direction="column"
      px="6"
      py="4"
      pb="calc(env(safe-area-inset-bottom) + var(--chakra-space-4))"
      alignItems="center"
      h="full"
      bg={background}
    >
      <LottieFile as={animation} boxSize="280px" my="auto" />
      <Flex align="center" gap="2">
        <Spinner size="sm" />{' '}
        <Text textStyle="copy_small" color={'neutral.white'}>
          Connecting...
        </Text>
      </Flex>
    </Flex>
  );
}
