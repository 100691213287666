import { createContext, useContext } from 'react';
import { useSelector } from '@xstate5/react';
import { Actor } from 'xstate5';
import { z } from 'zod';

import { zIsoDateTime } from '@strive/api';
import { assert, createDataStore, useDataStoreQuery } from '@strive/utils';
import { strivewarePairingMachine } from '@strive/wearable';

type StrivewareSetupActor = Actor<typeof strivewarePairingMachine>;
export const StrivewareSetupContext =
  createContext<StrivewareSetupActor | null>(null);

export function useSetupActor() {
  const actor = useContext(StrivewareSetupContext);
  assert(actor, 'StrivewareSetupContext not found');
  return actor;
}

export function useDiscoveredDevices() {
  const actor = useSetupActor();
  return useSelector(
    actor,
    (state) =>
      new Map(
        Array.from(state.context.devices).filter(([id, device]) =>
          state.context.deviceMatcher(device),
        ),
      ),
  );
}
export function useInitialDevicePairingStats() {
  const store = createDataStore({
    key: 'striveware_initial_device_pairing_stats',
    schema: z.object({
      startedPairingTime: z.date(zIsoDateTime).nullable(),
      pairingSuccessTime: z.date(zIsoDateTime).nullable(),
      singleDeviceFound: z.array(z.date(zIsoDateTime)).nullable(),
      multipleDevicesFound: z.array(z.date(zIsoDateTime)).nullable(),
      noDevicesFound: z.array(z.date(zIsoDateTime)).nullable(),
      pairingFailedTime: z.date(zIsoDateTime).nullable(),
    }),
  });

  const [pairingStats, setPairingStats] = useDataStoreQuery(store);
  return [pairingStats, setPairingStats] as const;
}
