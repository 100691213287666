import { useEffect } from 'react';
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { useSelector } from '@xstate5/react';

import {
  useDiscoveredDevices,
  useInitialDevicePairingStats,
  useSetupActor,
} from './hooks';
import { SetupNoDevices } from './setup-no-devices';

export function SetupSingleDevice() {
  const devices = useDiscoveredDevices();
  const setupActor = useSetupActor();
  const isPairing = useSelector(setupActor, (state) => state.context.isPairing);

  const canShowAll = useSelector(setupActor, (state) =>
    state.can({ type: 'Show all' }),
  );

  const [pairingStats, setPairingStats] = useInitialDevicePairingStats();
  useEffect(() => {
    if (pairingStats.data) {
      const singleDeviceFound = pairingStats.data.singleDeviceFound ?? [];
      setPairingStats.mutate({
        ...pairingStats.data,
        singleDeviceFound: [...singleDeviceFound, new Date()],
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const firstDevice = devices.get([...devices.keys()][0] ?? '');
  if (!firstDevice) return <SetupNoDevices />;

  const handleSubmit = () => {
    setupActor.send({ type: 'Pair', device: firstDevice });
  };

  return (
    <Flex direction="column" gap="6" h="full" align="center">
      <Image
        src="/images/striveware/black-ring-green-light-left.webp"
        w="360px"
        alt="Biometric Ring"
        my="auto"
        transform="scaleX(-1)"
      />

      <Flex
        direction="column"
        layerStyle="4dp"
        position="sticky"
        zIndex="1"
        bottom="0"
        gap="4"
        bg="white"
        color="black"
        w="full"
        borderTopRadius="xl"
        px="6"
        py="4"
        pb="calc(env(safe-area-inset-bottom) + var(--chakra-space-4))"
        mt="auto"
        align="center"
      >
        <Image
          src="/images/striveware/rocket.gif"
          alt=""
          w="48px"
          transform="rotate(38deg)"
        />
        <Text textStyle="h3">StriveWare Located!</Text>
        <Text textStyle="copy">
          Device name:{' '}
          <Text as="span" textStyle="copy" color="neutral.800">
            {firstDevice.name}
          </Text>
        </Text>
        {canShowAll ? (
          <Button
            variant="link"
            disabled={isPairing}
            onClick={() => setupActor.send({ type: 'Show all' })}
          >
            Show all devices
          </Button>
        ) : null}
        <Button
          variant="primary"
          w="full"
          onClick={handleSubmit}
          isLoading={isPairing}
          loadingText="Connecting..."
          _loading={{
            bg: 'primary.300',
            color: 'neutral.white',
          }}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
}
