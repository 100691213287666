import { useEffect, useMemo } from 'react';
import {
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { LottieFile } from '@arena-labs/strive2-ui';

import { ContactModal } from '../../../support/contact-modal';
import { useInitialDevicePairingStats, useSetupActor } from './hooks';

export function SetupNoDevices() {
  const setupActor = useSetupActor();
  const animation = useMemo(
    () => import('./scanning-for-devices.lottie.json'),
    [],
  );
  const helpDisclosure = useDisclosure();
  const analytics = useAnalytics();
  const [pairingStats, setPairingStats] = useInitialDevicePairingStats();

  useEffect(() => {
    const noDevicesFound = pairingStats.data?.noDevicesFound ?? [];
    const now = new Date();
    analytics.logEvent(
      GeneralTrackingEvent.UserInitiatedStrivewarePairingStats,
      {
        ...pairingStats.data,
        pairingFailedTime: new Date(),
        noDevicesFound: [...noDevicesFound, now],
      },
    );
    setPairingStats.mutate({
      startedPairingTime: null,
      pairingSuccessTime: null,
      singleDeviceFound: null,
      multipleDevicesFound: null,
      noDevicesFound: null,
      pairingFailedTime: null,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex
      direction="column"
      gap="3"
      px="6"
      py="4"
      pb="calc(env(safe-area-inset-bottom) + var(--chakra-space-4))"
      h="full"
    >
      <Text textStyle="h1" as="h1" color="neutral.white">
        Unable to locate your StriveWare
      </Text>

      <LottieFile as={animation} boxSize="280px" my="auto" alignSelf="center" />

      <Text textStyle="copy" color="neutral.100">
        Still not pairing? Try this:
      </Text>
      <UnorderedList
        ml="8"
        textStyle={'copy_small'}
        spacing="2"
        color="neutral.100"
      >
        <ListItem>Turn off your phone bluetooth and turn it on again.</ListItem>
        <ListItem>
          Check if the ring has battery by showing the green light.
        </ListItem>
      </UnorderedList>
      <Button
        mt="auto"
        onClick={() => {
          helpDisclosure.onOpen();
        }}
        variant="secondary"
      >
        Strive Support
      </Button>
      <Button
        onClick={() => {
          if (pairingStats.data) {
            const now = new Date();
            setPairingStats.mutate({
              ...pairingStats.data,
              startedPairingTime: now,
            });
          }
          setupActor.send({ type: 'Start again' });
        }}
        variant="primary"
      >
        Try Again
      </Button>
      <ContactModal {...helpDisclosure} />
    </Flex>
  );
}
