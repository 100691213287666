import { useGradient } from '@arena-labs/strive2-ui';

import { AppLayout } from '../../layout/app-layout';

export type StrivewareLayoutProps = {
  banner?: React.ReactNode;
  children: React.ReactNode;
};

export function StrivewareLayout({ banner, children }: StrivewareLayoutProps) {
  const background = useGradient('StriveWare.bgLightGradient');
  return (
    <AppLayout
      banner={banner}
      footer={false}
      contentProps={{
        bg: background,
        h: 'full',
        maxH: 'full',
        overflow: 'hidden',
      }}
    >
      {children}
    </AppLayout>
  );
}
